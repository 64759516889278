/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-toastify/dist/ReactToastify.css';

.Toastify {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

#root {
  background-color: rgb(243 244 246);
}

.custom-textfield .MuiOutlinedInput-root {
  & fieldset {
    border-color: rgb(156 163 175);
  }
  &:hover fieldset {
    border-color: rgb(156 163 175);
  }
  &.Mui-focused fieldset {
    border-width: 1px;
    border-color: rgb(107 114 128);
  }
}

input[type="number"] {         /* Modern browsers */
  text-align: right;
}